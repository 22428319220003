import { useEffect, useMemo, useState } from 'react';
import {
    BrowserRouter,
    Route,
    Routes,
    useNavigate,
    Navigate,
    useParams,
    Outlet,
} from 'react-router-dom';

import { ThemeProvider, StyledEngineProvider, Box, Stack, Typography } from '@mui/material';
import { theme } from './theme';
import LoadingButton from '@mui/lab/LoadingButton';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import {
    // SelectPaymentTerms,
    AgreeToTerms,
    SelectFinalPaymentTerms,
} from '@lendica/select-paylater-terms';
import { PhoneAuth, ProtectedRoute, useAccountContext, invalidateToken } from '@lendica/auth';
import { matchBackend, matchHostname } from '@lendica/utils';

import { AppContainer, useAppContainerContext } from './AppContainer';
import { AppData, useAppDataContext, AuthContainer } from './AppData';

import { StatusPage } from './StatusPage';
import { Fallback } from './Fallback';
import { ShopifyApproved, ShopifyPending, ShopifyRejected, ExceedsLimit } from './CheckoutStatuses';
import { ApplicationForm, OfferCalculator } from './ApplicationForm';
import { ConfirmBank } from './ConfirmBank';
import { SelectPaymentTermsView } from './views';

import _ from 'lodash';
import axios from 'axios';

export const SelectFinalPaymentTermsView = ({ onTermSelected, onSelected }) => {
    const { billId, offerId } = useParams();
    const { bill, offers, isLoading } = useAppDataContext();

    useEffect(() => {
        scroll({
            top: 0,
        });
    }, []);

    return (
        !isLoading &&
        !!bill &&
        !!offers && (
            <SelectFinalPaymentTerms
                bill={bill}
                offers={offers}
                onTermSelected={offer => onTermSelected(billId, offer)}
                onSelected={offer => onSelected(billId, offer)}
            />
        )
    );
};

export const AgreeOnTermsView = ({ onGoBack, onAgree }) => {
    const { billId, offerId } = useParams();
    const { offers, loadOffers, createDeal, isLoading, setLoading } = useAppDataContext();
    const navigate = useNavigate();

    useEffect(() => {
        const getOffers = async () => {
            // setLoading(true);
            scroll({
                top: 0,
            });
            await loadOffers(billId);
            // setLoading(false);
        };
        getOffers();
    }, []);

    const offer = useMemo(() => offers && offers?.find(item => item.id === offerId), [offers]);

    return (
        !isLoading &&
        (!!offer ? (
            <AgreeToTerms
                offer={offer}
                onGoBack={() => onGoBack(billId, offerId)}
                onAgree={async () => {
                    await createDeal(billId, offerId, onAgree);
                }}
            />
        ) : (
            <SelectFinalPaymentTermsView
                onSelected={(billId, offerId) => {
                    navigate(`/${billId}/agree-on-terms/${offerId}`);
                }}
                onTermSelected={(billId, offerId) => {
                    navigate(`/${billId}/select-terms/final/${offerId}`);
                }}
            />
        ))
    );
};

const Home = () => {
    return <Outlet />;
};

const AuthPages = () => {
    const { toggleFullscreen } = useAppContainerContext();
    const { billId } = useParams();
    const navigate = useNavigate();

    const { storePortedTokens } = useAccountContext();

    useEffect(() => {
        toggleFullscreen();
        return () => toggleFullscreen();
    }, []);
    return (
        <PhoneAuth
            baseURL={matchBackend()}
            onSuccess={async tokens => {
                await storePortedTokens(tokens);
                setTimeout(() => {
                    navigate(`/${billId}/bill`);
                }, 100);
            }}
        />
    );
};

const LoggedInAs = () => {
    const { billId, offerId } = useParams();
    const { addBill, getCompanyDetails, company, redirect } = useAppDataContext();
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();

    const { clearAccounts } = useAccountContext();

    useEffect(() => {
        (async () => {
            await getCompanyDetails(billId);
        })();
    }, []);

    useEffect(() => {
        if (!!redirect && isLoading) {
            // console.log('navigate redirect', redirect);
            navigate(redirect);
            setIsLoading(false);
        }
    }, [redirect]);

    const handleNext = async () => {
        setIsLoading(true);
        await addBill(billId, offerId);
    };

    const handleLogout = async () => {
        setIsLoading(true);
        try {
            await invalidateToken();
            await clearAccounts();
            navigate(`/${billId}/login`);
        } catch (e) {
            console.log(e);
        } finally {
            setIsLoading(false);
        }
    };
    return (
        !isLoading &&
        !!company &&
        !_.isEmpty(company) && (
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-evenly',
                    alignItems: 'center',
                    height: '100%',
                }}
            >
                <Stack
                    direction="column"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={360}
                    spacing={2}
                >
                    <Typography variant="overline">Logged in as</Typography>
                    {/* <Avatar>{`${company?.first_name[0]}${company?.last_name[0]}`}</Avatar>
                    <Typography variant="body2">{`Company: ${company?.company_name}`}</Typography>
                    <Typography>{`Email: ${company?.email}`}</Typography> */}
                    <Typography color="text.secondary" variant="subtitle1">
                        Phone number
                    </Typography>
                    <Typography variant="h5">{`+1 (***) ***-${company?.phone_number?.slice(
                        -4
                    )}`}</Typography>
                </Stack>
                <Stack
                    direction="column"
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={360}
                    spacing={2}
                >
                    <LoadingButton
                        loading={isLoading}
                        variant="contained"
                        color="primary"
                        fullWidth
                        sx={{ boxShadow: 'none' }}
                        onClick={handleNext}
                    >
                        Continue
                    </LoadingButton>
                    <LoadingButton
                        loading={isLoading}
                        variant="outlined"
                        color="primary"
                        fullWidth
                        onClick={handleLogout}
                    >
                        Use a different number
                    </LoadingButton>
                </Stack>
            </Box>
        )
    );
};

const AddBillView = () => {
    const { billId, offerId } = useParams();
    const { addBill, redirect } = useAppDataContext();

    useEffect(() => {
        const addNewBill = async () => {
            await addBill(billId, offerId);
        };
        addNewBill();
    }, []);

    return redirect && <Navigate to={redirect} />;
};

const Routing = () => {
    const navigate = useNavigate();
    const { interceptIfTokenExists } = useAppDataContext();
    const { company } = useAppDataContext();

    const selectPaymentTerms = (
        <SelectPaymentTermsView
            onSelected={(billId, offer) => {
                const res = interceptIfTokenExists();
                // console.log('trigger interceptIfTokenExists');
                if (res) {
                    // console.log('trigger navigate /profile');
                    return navigate(`/${billId}/profile`);
                }
                // console.log('if not res after interceptIfTokenExists navigate /bill');
                navigate(`/${billId}/bill/${offer?.id}`);
            }}
            onTermSelected={(billId, offer) => {
                navigate(`/${billId}/select-terms/preview/${offer?.id}`);
            }}
        />
    );

    const isCheckout = name => {
        return [
            'altametrics',
            'plum',
            'teaica',
            'black label',
            'blacklabel',
            'blackreplica',
            'vizocom',
            'stash',
            'mainstem',
        ].some(vendor => name.toLowerCase().includes(vendor));
    };

    const skipVendor = () => {
        const partnerName = company?.partner_name;
        if (!partnerName) return true;
        return ['foundation', 'versacloud'].some(partner =>
            partnerName.toLowerCase().includes(partner)
        );
    };

    const navigateToVendor = async (billId, offerId) => {
        const bill = (await axios.get(`/company/bills?id=${billId}`)).data;
        // check if bank and vendor are filled
        if (isCheckout(bill?.vendor_name)) {
            navigate(`/${billId}/agree-on-terms/${offerId}`);
        } else {
            navigate(`/${billId}/confirm-bank/${offerId}`);
        }
    };

    const selectFinalPaymentTerms = (
        <SelectFinalPaymentTermsView
            onSelected={(billId, offer) => {
                // check to see if vendor is not in [altametrics, stash, mainstem ] then navigate to add vendor
                navigateToVendor(billId, offer?.id);
            }}
            onTermSelected={(billId, offer) => {
                navigate(`/${billId}/select-terms/final/${offer?.id}`);
            }}
        />
    );

    return (
        <Routes>
            <Route path="/" element={<Home />}>
                <Route path="/partner" element={<Home />} />
                <Route path="/:billId">
                    <Route path="select-terms/preview" element={selectPaymentTerms} />
                    <Route path="select-terms/preview/:offerId" element={selectPaymentTerms} />
                    <Route element={<ProtectedRoute redirectPath={'login'} />}>
                        <Route path="profile" element={<LoggedInAs />} />
                        <Route path="profile/*" element={<LoggedInAs />} />
                        <Route path="bill" element={<AddBillView />} />
                        <Route path="bill/:offerId" element={<AddBillView />} />
                        <Route path="select-terms/final" element={selectFinalPaymentTerms} />
                        <Route
                            path="select-terms/final/:offerId"
                            element={selectFinalPaymentTerms}
                        />
                        <Route
                            path="agree-on-terms/:offerId"
                            element={
                                <AgreeOnTermsView
                                    onGoBack={(billId, offerId) => {
                                        navigate(`/${billId}/select-terms/final/${offerId}`);
                                    }}
                                    onAgree={billId => {
                                        // navigate(`/${billId}/confirm-bank`);
                                        navigate(`/${billId}/approved`);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="confirm-bank/:offerId"
                            element={<ConfirmBank skipVendor={skipVendor()} />}
                        />
                        <Route
                            path="declined"
                            element={
                                <StatusPage
                                    title="Your application is not approved this time"
                                    subtitle="You can track your payments and view reports in the portal."
                                    // duration={25}
                                    actionLabel="Go Now"
                                    action={() => {
                                        window?.location?.ancestorOrigins?.length > 0
                                            ? window
                                                  .open(
                                                      `https://portal.${matchHostname()}/`,
                                                      '_blank'
                                                  )
                                                  .focus()
                                            : (window.location = `https://portal.${matchHostname()}/`);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="pending"
                            element={
                                <StatusPage
                                    title="Your application is being processed"
                                    subtitle="You can track your payments and view reports in the portal."
                                    // duration={20}
                                    actionLabel="Go Now"
                                    action={() => {
                                        window?.location?.ancestorOrigins?.length > 0
                                            ? window
                                                  .open(
                                                      `https://portal.${matchHostname()}/`,
                                                      '_blank'
                                                  )
                                                  .focus()
                                            : (window.location = `https://portal.${matchHostname()}/`);
                                    }}
                                />
                            }
                        />
                        <Route
                            path="submitted"
                            element={
                                <StatusPage
                                    title="This bill has already been submitted"
                                    subtitle="You can track your deal status in portal."
                                    duration={20}
                                    actionLabel="Go Now"
                                    action={() => {
                                        window?.location?.ancestorOrigins?.length > 0
                                            ? window
                                                  .open(
                                                      `https://portal.${matchHostname()}/`,
                                                      '_blank'
                                                  )
                                                  .focus()
                                            : (window.location = `https://portal.${matchHostname()}/`);
                                    }}
                                />
                            }
                        />
                        <Route path=":offerId/*" element={<ApplicationForm />} />
                        <Route path="approved" element={<ShopifyApproved />} />
                        <Route path="shop/pending" element={<ShopifyPending />} />
                        <Route path="shop/sorry" element={<ShopifyRejected />} />
                    </Route>
                    <Route path="login/*" element={<AuthPages />} />
                </Route>
                <Route
                    path="/notfound"
                    element={
                        <StatusPage
                            title="Invalid Invoice"
                            subtitle="Looks like the link is outdated. Contact us if you need help."
                            duration={12}
                            actionLabel="Get Help"
                            action={() => {
                                location = 'https://home.golendica.com/#contact';
                            }}
                        />
                    }
                />
                <Route
                    path="/error"
                    element={
                        <StatusPage
                            title="Something Went Wrong"
                            subtitle="Looks like there's an error with your request. Contact us if you need help."
                            duration={20}
                            actionLabel="Get Help"
                            action={() => {
                                location = 'https://home.golendica.com/#contact';
                            }}
                        />
                    }
                />
                <Route path="/limit" element={<ExceedsLimit />} />
                <Route path="/home" element={<Fallback />} />
                <Route path="/offer-calculator" element={<OfferCalculator />} />
            </Route>
            <Route path="/*" element={<Navigate to="/home" />} />
        </Routes>
    );
};

export const App = () => {
    return (
        <BrowserRouter>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={theme}>
                        <AuthContainer>
                            <AppData>
                                <AppContainer>
                                    <Routing />
                                </AppContainer>
                            </AppData>
                        </AuthContainer>
                    </ThemeProvider>
                </StyledEngineProvider>
            </LocalizationProvider>
        </BrowserRouter>
    );
};
